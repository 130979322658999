<template>
  <div
    draggable
    @dragstart.stop="startDrag"
    @drag.stop="draging"
    @dragend.stop="stopDrag"
    class="icon-md icon-resize"
  ></div>
</template>
<script>
let MIN_WIDTH = 35;
let MIN_HEIGHT = 55;
export default {
  data() {
    return {
      x: -1,
      y: -1,
      lastComputeTime: 0
    };
  },
  methods: {
    startDrag(e) {
      this.x = e.x;
      this.y = e.y;
    },
    draging(e, key) {
      if (this.x < 0 || this.y < 0) {
        return;
      }
      let now = Date.now();
      if (now < this.lastComputeTime + 16) {
        // 节流
        return;
      }
      this.lastComputeTime = now;
      // 这里的 e.x 在最后 为什么会莫名其妙产生一个0 ？？
      e.x && this.setRegion(this.parentRegion, e.x - this.x, e.y - this.y);
      this.x = e.x;
      this.y = e.y;
    },
    stopDrag(e, key) {
      this.x = -1;
      this.y = -1;
      this.$emit("resize");
    },
    setRegion(region, offW, offH) {
      let height = Number.parseInt(region.height);
      if (offW < 0 && height + 16 >= region.maxH) {
        return;
      }
      let left = Number.parseInt(region.left);
      let right = Number.parseInt(region.right) - offW;
      let maxRight = this.parentRegion.maxW - left - MIN_WIDTH;
      if (right > maxRight) {
        right = maxRight;
      }
      if (right < 0) {
        right = 0;
      }
      region.right = right + "px";
      this.refreshParentFn();
    }
  },
  props: {
    parentRegion: {
      required: true
    },
    refreshParentFn: {
      default() {
        return () => {
          console.warn(
            "If the parent's height should be changed,you must offer the function!"
          );
        };
      }
    }
  }
};
</script>
<style scoped>
</style>
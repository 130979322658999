<template>
  <div ref="wrapper">
    <div style="padding-bottom:.5em;overflow:hidden;">
      <div>
        <!--  style="float:right;" -->
        <Button class="addTextBtn" @click="addText" v-if="!contentLocked">文本</Button>
        <span>设置字体颜色:</span>
        <Button
          :class="currentColor==='black'?'active':''"
          class="colorBtn black"
          @click="setColor('black')"
        ></Button>
        <Button
          :class="currentColor==='white'?'active':''"
          class="colorBtn white"
          @click="setColor('white')"
        ></Button>
      </div>
    </div>
    <div
      :style="{width:Width+'px',height:Height+'px'}"
      v-if="Width"
      class="drag-containner"
      @dragover.stop
    >
      <img :style="imgRegion" v-if="info.url" class="base-img" :src="info.url" />
      <TextItem
        :style="{fontSize:fontSize+'px'}"
        v-for="(txtObj,index) in info.divElementList "
        v-show="!txtObj.paragraphKey||txtObj.divContent"
        :closeAble="!txtObj.paragraphKey&&!contentLocked"
        :placeholder="txtObj.placeholder||'这是正文'"
        :key="index"
        :region="textRegions[index]"
        :txtObj="txtObj"
        :contentLocked="contentLocked||!!txtObj.paragraphKey"
        :fontColor="txtObj.fontColor||info.fontColor"
        @clickDelete="deleteText(index)"
        @textChange="onTextDivChange(index)"
      />
    </div>
  </div>
</template>

<script>
import TextItem from "./TextItem";

export default {
  props: {
    info: {
      required: true
    },
    contentLocked: {}
  },
  data() {
    return {
      Width: 0,
      Height: 0,
      imgRegion: {
        top: 0,
        left: 0,
        right: 0
      },
      textRegions: [],
      currentColor: "",
      fontSize: 16
    };
  },
  components: { TextItem },
  methods: {
    onTextDivChange(ind) {
      let region = this.textRegions[ind];
      let txtObj = this.info.divElementList[ind];
      txtObj.divX = Number.parseInt(region.left) / this.Width;
      txtObj.divY = Number.parseInt(region.top) / this.Height;
      let right = Number.parseInt(region.right) / this.Width;
      txtObj.divWidth = 1 - right - txtObj.divX;
      this.pageChange();
    },
    pageChange() {
      // 三种种情况 发送事件：文字（内容，位置，大小）改变，颜色改变，删除文本框
      this.$emit("pageChange");
    },
    deleteText(ind) {
      this.info.divElementList.splice(ind, 1);
      this.textRegions.splice(ind, 1);
      this.pageChange();
    },
    setColor(color) {
      if (this.currentColor !== color) {
        this.currentColor = color;
        this.info.fontColor = color;
        this.info.divElementList.forEach(txtObj => {
          txtObj.fontColor = color;
        });
        this.$forceUpdate();
        this.pageChange();
      }
    },
    addText() {
      if (this.info.divElementList.some(ele => !ele.paragraphKey)) {
        this.$Message.info("只能添加一段文本");
        return;
      }
      let left = 0,
        right = 0.5,
        top = 0;

      this.info.divElementList.push({
        divContent: "",
        divX: left,
        divY: top,
        divWidth: 1 - left - right,
        fontColor: this.currentColor || "black"
      });
      this.textRegions.push({
        left: left * this.Width + "px",
        right: right * this.Width + "px",
        top: top * this.Height + "px",
        height: "35px",
        maxW: this.Width,
        maxH: this.Height
      });
    },
    init() {
      this.textRegions = [];
      this.currentColor = this.info.fontColor;
      for (let { divX, divY, divWidth, fontColor } of this.info
        .divElementList) {
        if (!this.currentColor) {
          this.currentColor = fontColor;
        }
        this.textRegions.push({
          left: divX * this.Width + "px",
          right: (1 - divWidth - divX) * this.Width + "px",
          top: divY * this.Height + "px",
          maxW: this.Width,
          maxH: this.Height,
          height: 0
        });
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.Width = this.$refs.wrapper.clientWidth;
      this.Height = this.Width * this.info.lengthWidthRatio;
      this.fontSize = Math.round(this.Width * 0.06);
      this.init();
    }, 200);
  },
  watch: {
    info: {
      handler() {
        this.init();
      }
    },
    "info.divElementList.length": {
      handler() {
        this.init();
      }
    }
  }
};
</script>

<style>
.drag-containner {
  position: relative;
  border: 1px solid #ddd;
}
.base-img {
  position: absolute;
}
.base-img {
  width: 100%;
  height: 100%;
}
.addTextBtn {
  margin-right: 1.5em;
}

.colorBtn {
  display: inline-block;
  width: 1.5em;
  height: 2.5em;
  margin-left: 0.5em;
}
.colorBtn.black {
  background: black;
}
.colorBtn.active {
  box-shadow: 2px 0 2px, -2px 0 2px;
}
</style>
<template>
  <div>
    <ImgSelect
      :imgHolders="versePages"
      :config="{ matchBy: selectFn, file: 'file' }"
      @imgsChange="imgsChange"
    />
    <div class="up_tip">
      <p>上传组图</p>
      <p>1）支持单图/多图上传，格式；JPG，PNG</p>
      <p>
        2）封面图命名；01，单段内容单张图按依次序号；1，2，3...，单段内容多张图按序号+次序号，如；1.1，1.2，1.3...
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    versePages: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectFn(file, imgList) {
      let name = file.name;
      let ind = name.lastIndexOf(".");
      let type = name.substring(ind + 1);
      name = +name.substring(0, ind);
      if (!imgList[name - 1]) {
        // 未匹配上：
        return -1;
      }
      return name - 1;
    },
    imgsChange(inds) {
      this.$emit("imgsChange", inds);
    },
  },
};
</script>
 <style scoped>
</style>

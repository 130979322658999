<template>
  <div
    class="icon-md icon-move-x"
    draggable="true"
    @dragstart.stop="dragStart"
    @drag.stop="draging"
    @dragend.stop="dargEnd"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      x: -1,
      y: -1,
      lastComputeTime: 0
    };
  },
  methods: {
    dragStart(e) {
      this.x = e.x;
      this.y = e.y;
    },
    draging(e) {
      if (this.x < 0 || this.y < 0) {
        return;
      }
      let now = Date.now();
      if (now < this.lastComputeTime + 16) {
        // 节流
        return;
      }
      this.lastComputeTime = now;
      e.x && this.moveRegion(e.x - this.x, e.y - this.y);
      this.y = e.y;
      this.x = e.x;
    },
    moveRegion(offX, offY) {
      let region = this.regionStyle;
      let left = Number.parseInt(region.left) + offX;
      let right = Number.parseInt(region.right) - offX;
      let top = Number.parseInt(region.top) + offY;
      let bottom = Math.round(
        region.maxH - top - Number.parseInt(region.height) - offY
      );
      if (left < 0) {
        right += left;
        left = 0;
      }
      if (right < 0) {
        left += right;
        right = 0;
      }
      // 
      const minBottom = 3;
      if (bottom < minBottom) {
        top += bottom - minBottom;
        bottom += minBottom - bottom;
      }
      if (top < 0) {
        bottom += top;
        top = 0;
      }
      region.left = left + "px";
      region.right = right + "px";
      region.top = top + "px";
    },
    dargEnd(e) {
      this.x = this.y = -1;
      this.$emit("moveEnd");
    }
  },
  props: {
    regionStyle: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style scoped>
</style>


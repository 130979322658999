<template>
  <div class="txt-box" :style="region">
    <div class="close icon-md icon-close" @click="$emit('clickDelete')" v-if="closeAble"></div>
    <MovableRegion @moveEnd="onRegionChange()" :regionStyle="region" class="move" ref="moveTool"></MovableRegion>
    <ResizeAnchor
      class="resize"
      :parentRegion="region"
      :refreshParentFn="refreshDiv"
      @resize="onRegionChange()"
    />
    <TextArea
      :placeholder="placeholder"
      ref="txtArea"
      v-model="txtObj.divContent"
      :disabled="contentLocked"
      @input="textContentChange()"
      class="text-block verse-text"
      :style="{'color':fontColor}"
    />
  </div>
</template>

<script>
import MovableRegion from "./MovableRegion";
import ResizeAnchor from "./ResizeAnchor";
export default {
  props: {
    region: {
      required: true
    },
    txtObj: {
      required: true
    },
    fontColor: {},
    contentLocked: {
      default: false
    },
    closeAble: {
      default: true
    },
    placeholder: {
      default: "content by add!"
    }
  },
  components: { MovableRegion, ResizeAnchor },
  data() {
    return {
      lastText: ""
    };
  },
  methods: {
    refreshDiv() {
      let areaCom = this.$refs.txtArea;
      areaCom.autoHeight();
      this.$nextTick(() => {
        this.region.height = areaCom.$el.clientHeight + "px";
        this.$refs.moveTool.moveRegion(0, 0);
      });
    },
    onRegionChange() {
      this.$emit("textChange");
    },
    textContentChange() {
      setTimeout(() => {
        // validate or reduction:
        if (Number.parseInt(this.region.height) > this.region.maxH) {
          if (this.txtObj.divContent.length > this.lastText.length) {
            this.txtObj.divContent = this.lastText;
          }
          return;
        }
        this.lastText = this.txtObj.divContent;
        // compute new height:
        this.region.height = this.$refs.txtArea.$el.clientHeight + "px";

        // execute change:
        this.$refs.moveTool.moveRegion(0, 0);
        this.onRegionChange();
      }, 100);
    }
  },
  mounted() {
    setTimeout(() => {
      let txtArea = this.$refs.txtArea.$el;
      this.region.height = txtArea.clientHeight + "px";
    }, 100);
    this.lastText = this.txtObj.divContent;
  }
};
</script>

<style scoped>
.verse-text{
  /* padding-right: .25em; */
}
.txt-box {
  position: absolute;
}

.text-block {
  width: 100%;
  overflow-y: hidden;
  background-color: transparent;
  box-sizing: border-box;  
  resize: none;
  margin: 0;
  font-weight: 500;
  
}
.close {
  transform: translateY(-6px) translateX(100%);
}

.move {
  transform: translateY(calc(100%)) translateX(100%);
}
.resize {
  transform: translateY(calc(200% + 6px)) translateX(100%);
}
.close,
.move,
.resize {
  position: absolute;
  right: -5px;
}
</style>
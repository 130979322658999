<template>
  <div>
    <Input
      style="width: 8.2em"
      v-if="'cnVerse' === hb.contentType && 'cnVerseProse' === hb.category"
      v-model="hb.attributeList[0].dynasty"
      :disabled="contentLocked"
      @input="changeTextInFirstPage('dynasty', $event)"
      placeholder="朝代"
    />
    <Input
      style="width: 8.2em; margin-left: 2em"
      v-if="'cnVerse' === hb.contentType && 'cnVerseProse' === hb.category"
      v-model="hb.attributeList[0].author"
      :disabled="contentLocked"
      @input="changeTextInFirstPage('author', $event)"
      placeholder="作者"
    />
    <div class="flex" style="margin-top: 1em">
      <EditorSider
        :activeIndex.sync="currentIndex"
        :len="hb.backGroundList.length"
        :isEmptyFn="isContentEmpty"
        @clickAdd="addContent"
        :modifiable="!contentLocked"
        @clickRemove="removeContent"
      />

      <div class="editor">
        <div>
          <DragTextEditor
            :contentLocked="contentLocked"
            ref="textEditor"
            @pageChange="pageChange"
            :info="hb.backGroundList[currentIndex]"
          />
          <div>
            <span
              >汉字数：{{ (hb.numCns && hb.numCns[0]) || 0 }} &nbsp;&nbsp;</span
            >
            <span>英文数：{{ (hb.numEns && hb.numEns[0]) || 0 }}</span>
          </div>
        </div>

        <UploadImg
          class="uploader"
          @imgsChange="imgsChange"
          :versePages="hb.backGroundList"
          v-if="showImgUploader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DragTextEditor from "./DragTextEditor";
import UploadImg from "./UploadImg4Verse";
import { countString } from "../countService";
import { getContrastData } from "../../../api/hbService";
export default {
  props: {
    hb: {
      required: true,
    },
    showImgUploader: {
      type: Boolean,
    },
    contentLocked: {
      default: false,
    },
  },
  components: { DragTextEditor, UploadImg },
  data() {
    return {
      contrastDataList: [],
      lastContentStr: undefined,
      txtObj4title: {
        paragraphKey: "t",
        divContent: "",
        placeholder: "标题",
        divY: "0.04",
        divX: "0.3",
        divHeight: "",
        divWidth: "0.3",
      },
      txtObj4dynasty: {
        paragraphKey: "dynasty",
        placeholder: "朝代",
        divContent: "",
        divY: "0.13",
        divX: "0.27",
        divHeight: "",
        divWidth: "0.22",
      },
      txtObj4author: {
        paragraphKey: "author",
        placeholder: "作者",
        divContent: "",
        divY: "0.13",
        divX: "0.5",
        divHeight: "",
        divWidth: "0.20",
      },
    };
  },
  methods: {
    isContentEmpty(ind) {
      let buf = this.hb.backGroundList[ind];
      if (buf.url.trim()) {
        console.log("has img");
        return false;
      }
      let txt = buf.divElementList.reduce(
        (res, item) => res + item.divContent.trim(),
        ""
      );
      if (txt) {
        console.log("has content:", txt);
        return false;
      }
      return true;
    },
    changeTextInFirstPage(key, text) {
      switch (key) {
        case "t":
          this.txtObj4title.divContent = text;
          break;
        case "dynasty":
          this.txtObj4dynasty.divContent = text;
          break;
        case "author":
          this.txtObj4author.divContent = text;
      }
      this.pageChange();
    },
    addContent() {
      this.hb.backGroundList.push({
        divElementList: [],
        lengthWidthRatio: 1.6,
        key: "",
        url: "",
        fontColor: "black",
      });
      this.currentIndex = this.hb.backGroundList.length - 1;
      this.$nextTick(() => {
        this.$refs.textEditor.addText();
      });
    },
    removeContent(ind) {
      if (this.currentIndex >= ind) {
        this.currentIndex--;
      }
      this.hb.backGroundList.splice(ind, 1);
    },
    imgsChange(inds) {
      if (inds.indexOf(this.currentIndex) >= 0) {
        this.pageChange();
      }
    },
    pageChange() {
      let allStr = this.hb.backGroundList
        .reduce((list, picObj) => {
          let li = picObj.divElementList.reduce((lastValue, item) => {
            lastValue.push(item.divContent);
            return lastValue;
          }, []);
          return list.concat(li);
        }, [])
        .join("\n\n");

      let { numCn, numEn } = countString(allStr);
      this.$set(this.hb, "numCns", [numCn]);
      this.$set(this.hb, "numEns", [numEn]);
      this.$emit("change");
    },
  },
  beforeMount() {
    this.currentIndex = 0;
  },
  mounted() {},
  watch: {
    hb: {
      handler() {
        if (!this.hb) {
          return;
        }
        if ("cnVerse" === this.hb.contentType && !this.hb.category) {
          // 中文韵文的二级分类默认是普通韵文
          this.hb.category = "cnVerse";
        }
        if (!this.hb.backGroundList.length) {
          this.addContent();
        }
        // ==========
        let [title, dynasty, author] = this.hb.backGroundList[0].divElementList;
        if (title && title.paragraphKey === this.txtObj4title.paragraphKey) {
          this.txtObj4title = title;
        }
        if (
          dynasty &&
          dynasty.paragraphKey === this.txtObj4dynasty.paragraphKey
        ) {
          this.txtObj4dynasty = dynasty;
        } else {
          this.changeTextInFirstPage(
            "dynasty",
            this.hb.attributeList[0].dynasty
          );
        }
        if (author && author.paragraphKey === this.txtObj4author.paragraphKey) {
          this.txtObj4author = author;
        } else {
          this.changeTextInFirstPage("author", this.hb.attributeList[0].author);
        }

        
        this.pageChange();
      },
      immediate: true,
    },
    "hb.title": {
      handler() {
        this.changeTextInFirstPage("t", this.hb.title);
      },
      immediate: true,
    },
    "hb.category": {
      handler() {
        if ("cnVerse" != this.hb.contentType) {
          return;
        }
        let list = this.hb.backGroundList[0].divElementList;
        if ("cnVerseProse" === this.hb.category) {
          // 中文韵文 下的 古诗词，需要将标题同步到第一页的文字文字框中
          // 组装4个
          let last = list.pop();
          list.splice(
            0,
            list.length,
            this.txtObj4title,
            this.txtObj4dynasty,
            this.txtObj4author
          );
          if (last && !last.paragraphKey) {
            list.push(last);
          }
        }
        if ("cnVerseProse" !== this.hb.category) {
          let last = list.pop();
          list.splice(0);
          if (last && !last.paragraphKey) {
            list.push(last);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.uploader {
  margin-top: 20px;
}
.subtitle {
  width: calc(50% - 3em);
  margin-bottom: 1em;
}
.editor {
  margin-left: 1em;
  flex: 1;
}
.operte {
  width: 6.25em;
}
</style>